import { Config } from './types';
export const baseApiUri = process.env.REACT_APP_API_URL || 'https://backoffice-ecgz.onrender.com/api/v1';

export const config: Config = {
    name: process.env.REACT_APP_COMPANY_NAME || 'Doctolib',
    logoUrl: process.env.REACT_APP_COMPANY_LOGO || 'https://imagedelivery.net/YV7tiPr1pNu8eWcEhMhZuA/6feb3404-a143-4109-d122-51cb8d1a2e00/public',
    primaryColor: process.env.REACT_APP_PRIMARY_COLOR || '#0F7ACA',
    secondaryColor: process.env.REACT_APP_SECONDARY_COLOR || '#fffff',
    thirdColor: process.env.REACT_APP_THIRD_COLOR || '#fffff',
};
